import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Bildgalleri = () => (
    <Layout>
        <SEO title="Bildgalleri" />
        <div className="mainWrapper">
            <h2>Bildgalleri</h2>
            <p>Här visas alla bilder som går att välja till ditt Himlatak. Välj till vilken typ av fönster nedan.</p>

            <div className="windowSelector">
            <div className="windowItem">
                    <div className="button">
                        <Link to="/bildgalleri/fonster/">
                            <p>Fönster</p>
                        </Link>
                    </div>
                </div>
                <div className="windowItem">
                    <div className="button">
                        <Link to="/bildgalleri/takfonster/">
                            <p>Takfönster</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default Bildgalleri;